import React, { FC } from 'react';
import { Box } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';

export const HomeEmailSubscription: FC = () => {
  const { t } = useTranslate('home');
  return (
    <Box component="section" className="email-section">
      <h1 className="centered">
        <span className="text-span-2">
          {t('marketing.letUsSlideIntoYour')}{' '}
        </span>
        <span className="black-text">{t('marketing.mailbox')}</span>
        <span className="green-text">.</span>
      </h1>
      <p>{t('marketing.signUpNewsletter')}</p>
      <Box
        className="w-embed w-iframe"
        dangerouslySetInnerHTML={{
          __html:
            '<iframe width="540" height="360" src="https://3302b895.sibforms.com/serve/MUIEAHqeDyYMNIc33L4I_ORsQRaxzW_riRy7bCNgBAUe4wX2Y7KGUiP0-auSJnHlDSPu8nKYglgy_mm78W51Yp_tdJGKP1nbiDE6XYH1NiIAbNSLuXjysKRhFkkGmIJxeqFS8aG1LWT3tL0fbs2kc7fXiCt8jprY3bQgF8mjAwDnj8i_Qb5gXczkLk2VZyrgvnwTKJgc-js1KLbg" frameborder="0" scrolling="auto" allowfullscreen="" style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>',
        }}
      />
      <Box className="w-form">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          action="https://3302b895.sibforms.com/serve/MUIEAHstX3S5Aa1rJVij5QaBW2xlBR8ELlWmp6xADTa3jY9xgNjo1Co0EwdzpVJX__vg0JsWdge1rD3el6T8zWXCCN7qaQAGZ1fz2EhyZM2IYEqHFNzdcLzplTLAelG_8YWbEu-Ok-fNB52AV6JqiXqF2eQrkBkye8IGyCqioqr9y94RCiWQakXOmCgiExgJhevnUx6b_bJ3DyBv"
          method="post"
          className="form"
          data-wf-page-id="63ad42e0268d1809567bd4de"
          data-wf-element-id="aa3e9e16-5982-14f2-536e-4e75f03d20e3"
          aria-label="Email Form"
        />
        <Box
          className="success-message w-form-done"
          tabIndex={-1}
          role="region"
          aria-label="Email Form success"
        >
          <Box>
            <span className="text-span-3">{t('marketing.thankYou')}</span>{' '}
            {t('marketing.yourSubmissionReceived')}
          </Box>
        </Box>
        <Box
          className="error-message w-form-fail"
          tabIndex={-1}
          role="region"
          aria-label="Email Form failure"
        >
          <Box>{t('marketing.oops')}</Box>
        </Box>
      </Box>
    </Box>
  );
};
