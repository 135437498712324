import React, { FC } from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import TextImage from 'public/images/home/nPloy_AnEmployerIsLookingForYou.svg';
import useTranslate from 'utils/i18n/useTranslate';
import { homeHeroStyles } from './home-hero.styles';

export const HomeHero: FC = () => {
  const { t } = useTranslate('home');
  return (
    <Box sx={homeHeroStyles.wrapper}>
      <Image
        src={TextImage}
        width="455"
        alt="An Employer is looking for you"
        className="image-12"
        priority
      />
      <Typography variant="h6" sx={homeHeroStyles.subtitle}>
        {t('marketing.subtitle')}
      </Typography>
    </Box>
  );
};
