import React, { FC } from 'react';
import { Box } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import { IHomeCategoryItem } from './home-category-item.interface';
import { HomeCategoryLink } from './home-category-item.styles';

export const HomeCategoryItem: FC<IHomeCategoryItem> = ({
  title,
  count,
  iconClass,
  link,
}) => {
  const { t } = useTranslate('home');
  return (
    <HomeCategoryLink className="hh-job-ui" href={link}>
      <Box className="w-layout-hflex job-ui-flex">
        <Box className={`job-ui-icon ${iconClass}`} />
        {/* <Box className="job-ui-jobs-count">{count}</Box> */}
      </Box>
      <Box className="w-layout-vflex">
        <Box>{t('marketing.jobsIn')}</Box>
        <Box className="job-ui-field">{title}</Box>
      </Box>
    </HomeCategoryLink>
  );
};
