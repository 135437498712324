import React, { FC } from 'react';
import Head from 'next/head';
import { Box } from '@mui/material';
import { HomeBenefits, HomeCategories, HomeHero, HomePartners, HomeTestimonials } from 'components/home';
import { Layout } from 'components/layout';


/**
 * Home component.
 *
 * @returns {React.ReactElement} The rendered home page.
 */
const Home: FC = (): React.ReactElement => (
  <>
    <Head>
      <link
        rel="stylesheet"
        href="https://cdn.prod.website-files.com/63ad42e0268d180a497bd4dd/css/the-new-nploy-2023.webflow.1eee1f75e.css"
      />
    </Head>
    <Layout>
      <HomeHero />
      <HomeCategories />
      <Box className="blurred-gradient" />
      <HomePartners />
      <HomeBenefits />
      <HomeTestimonials />
    </Layout>
  </>
);

export default Home;
