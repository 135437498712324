export const homeTestimonialsStyles = {
  scrollable: {
    '&:hover': {
      overflow: 'auto',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
};
