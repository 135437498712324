import React, { FC } from 'react';
import Link from 'next/link';
import { Box, Typography } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';

export const HomeBenefits: FC = () => {
  const { t } = useTranslate('home');
  return (
    <Box component="section" className="homepage-benefits">
      <Box className="hb-phone" />
      <Box className="hb-benefit-card">
        <Box className="horizontal-flex-sb-top-align">
          <h3 className="hb-titles">
            {t('marketing.smart')} <br />
            {t('marketing.algorithm')}
          </h3>
          <Link
            href="/post/how-does-our-matching-algorithm-work"
            className="button dark small w-button"
          >
            {t('marketing.more')}
          </Link>
        </Box>
        <p>
          <strong>{t('marketing.letAiMatchYou')}</strong>{' '}
          {t('marketing.simplyCompleteYourProfile')}
        </p>
        <Box className="hb-visual _1" />
      </Box>
      <Box className="hb-benefit-card _2">
        <Box className="horizontal-flex-sb-top-align">
          <Typography variant="h3" className="hb-titles">
            {t('marketing.match')}
            <br />
            {t('marketing.request')}
          </Typography>
          <Link
            href="/post/you-a-dream-job-a-match-made-on-nploy"
            className="button dark small w-button"
          >
            {t('marketing.more')}
          </Link>
        </Box>
        <p>
          <strong>{t('marketing.letCompaniesApply')} </strong>
          {t('marketing.ifSomeoneFromHrThink')}
        </p>
        <Box className="hb-visual _3" />
      </Box>
      <Box className="hb-benefit-card _3">
        <Box className="horizontal-flex-sb-top-align">
          <Typography variant="h3" className="hb-titles">
            {t('marketing.anonymity')}
          </Typography>
          <Link
            href="/post/what-does-it-mean-to-be-anonymous-in-nploy"
            className="button dark small w-button"
          >
            {t('marketing.more')}
          </Link>
        </Box>
        <p>
          <strong>{t('marketing.onlyYourSkillsMatter')}</strong>
          {t('marketing.yourDataSecure')}
        </p>
        <Box className="hb-visual _2" />
      </Box>
    </Box>
  );
};
