export const homeCategories = [
  {
    id: 1,
    title: 'IT & Technology',
    icon: 'it',
    jobsCount: 45,
    link: '/jobs?job-category=it-and-technology',
  },
  {
    id: 2,
    title: 'Client & Customer Service',
    icon: 'cs',
    jobsCount: 65,
    link: '/jobs?job-category=client-and-customer-service',
  },
  {
    id: 3,
    title: 'Finance & Accounting',
    icon: 'finance',
    jobsCount: 23,
    link: '/jobs?job-category=accounting-finance-and-statistics',
  },
  {
    id: 4,
    title: 'Sales & Trade',
    icon: 'sales',
    jobsCount: 12,
    link: '/jobs?job-category=sales-and-trade',
  },
  {
    id: 5,
    title: 'marketing',
    icon: 'marketing',
    jobsCount: 8,
    link: '/jobs?job-category=marketing-and-communications-and-pr',
  },
  {
    id: 6,
    title: 'business, management & consulting',
    icon: 'biz',
    jobsCount: 8,
    link: '/jobs?job-category=business-management-and-consulting',
  },
  {
    id: 7,
    title: 'human resources',
    icon: 'hr',
    jobsCount: 32,
    link: '/jobs?job-category=hr-and-recruitment',
  },
  {
    id: 8,
    title: 'Design',
    icon: 'design',
    jobsCount: 7,
    link: '/jobs?job-category=design-and-creative-arts',
  },
];

export const nployPartners = [
  {
    id: 'toyota',
    name: 'toyota',
    logo: 'toyota',
  },
  {
    id: 'sap',
    name: 'sap',
    logo: 'sap',
  },
  {
    id: 'unicredit',
    name: 'unicredit',
    logo: 'unicredit',
  },
  {
    id: 'bic',
    name: 'bic',
    logo: 'bic',
  },
  {
    id: 'cocacola',
    name: 'cocacola',
    logo: 'cocacola',
  },
  {
    id: 'lidl',
    name: 'lidl',
    logo: 'lidl',
  },
  {
    id: 'hp',
    name: 'hp',
    logo: 'hp',
  },
  {
    id: 'gameloft',
    name: 'gameloft',
    logo: 'gameloft',
  },
  {
    id: 'adecco',
    name: 'adecco',
    logo: 'adecco',
  },
  {
    id: 'forbes',
    name: 'forbes',
    logo: 'forbes',
  },
  {
    id: 'atos',
    name: 'atos',
    logo: 'atos',
  },
  {
    id: 'ey',
    name: 'ey',
    logo: 'ey',
  },
  {
    id: 'accenture',
    name: 'accenture',
    logo: 'accenture',
  },
  {
    id: 'bunq',
    name: 'bunq',
    logo: 'bunq',
  },
  {
    id: 'officernd',
    name: 'officernd',
    logo: 'officernd',
  },
];
