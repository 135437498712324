import React, { FC } from 'react';
import { Box } from '@mui/material';
import parseHtml from 'html-react-parser';
import { IHomeTestimonialsComment } from './home-testimonials-comment.interface';

export const HomeTestimonialsComment: FC<IHomeTestimonialsComment> = ({
  name,
  comment,
  position,
}) => (
  <Box className="ht-testimonial-card">
    <h6>
      <strong>{name}</strong>
    </h6>
    <Box className="light-eina">{position}</Box>
    <Box className="spacer _15" />
    <h6 className="ht-testimonial-main-text">{parseHtml(comment)}</h6>
  </Box>
);
