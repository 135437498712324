import React, { FC } from 'react';
import Link from 'next/link';
import { Box } from '@mui/material';
import { nployFeedbacks } from 'constants/nploy-feedbacks';
import useTranslate from 'utils/i18n/useTranslate';
import { HomeTestimonialsComment } from '../home-testimonials-comment';
import { homeTestimonialsStyles } from './home-testimonials.styles';

export const HomeTestimonials: FC = () => {
  const { t } = useTranslate('home');

  return (
    <Box component="section" className="homepage-testimonials">
      <Box className="ht-top-section">
        <h1 className="white-text">
          {t('marketing.whyCandidates')}
          <br />
          &zwj;
          <span className="gradient-text bluered flipped">
            {t('marketing.love')}
          </span>{' '}
          {t('marketing.us')}
        </h1>
        <Box className="ht-popup-quotes">
          <Box className="ht-p-gradient-line">
            <Box className="ht-p-text-box">
              It was like a matchmaking system. It&apos;s pretty cool.
            </Box>
          </Box>
          <Box className="ht-p-carreer">Azure Complience Expert</Box>
          <Box className="ninja-small-icon homepage _2" />
        </Box>
        <Box className="ht-popup-quotes _2">
          <Box className="ht-p-gradient-line">
            <Box className="ht-p-text-box">
              I just created a profile and the employer found me.
            </Box>
          </Box>
          <Box className="ht-p-carreer">Incident Manaer</Box>
          <Box className="ninja-small-icon homepage" />
        </Box>
        <Box className="ht-popup-quotes _3">
          <Box className="ht-p-gradient-line">
            <Box className="ht-p-text-box">
              <strong>nPloy</strong> saved me from my old job.
            </Box>
          </Box>
          <Box className="ht-p-carreer">Incident Manaer</Box>
          <Box className="ninja-small-icon homepage _3" />
        </Box>
        <Box className="ht-gradient-blobs" />
        <Box className="ht-gradient-blobs _2" />
      </Box>
      <Box className="ht-bottom-section">
        <Box
          id="scrollableBox"
          className="scrollable"
          sx={homeTestimonialsStyles.scrollable}
        >
          <Box className="scrollablecontent">
            {nployFeedbacks.map(
              ({ id, comment, authorName, authorPosition }) => (
                <HomeTestimonialsComment
                  key={`nploy-feedback-${id}`}
                  comment={comment}
                  name={authorName}
                  position={authorPosition}
                />
              ),
            )}
          </Box>
        </Box>

        <Link
          href="/about-us/our-mobile-app"
          className="relative w-inline-block"
        >
          <Box className="button dark red">{t('marketing.downloadApp')}</Box>
          <Box className="button dark detail" />
        </Link>
      </Box>
    </Box>
  );
};
