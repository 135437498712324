import backgroundImg from 'public/images/home/65eeefb9c26df9cb4fab4f8a_nPloy_HomeHeroImage-min.png';
import theme from 'theme/theme';

export const homeHeroStyles = {
  wrapper: {
    backgroundImage: `url(${backgroundImg.src})`,
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '600px',
    marginTop: '-100px',
    paddingTop: '130px',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down(991)]: {
      backgroundSize: 'cover',
      height: '510px',
      marginTop: '-90px',
      paddingTop: '75px',
    },
    [theme.breakpoints.down(767)]: {
      height: '360px',
      paddingTop: '75px',
    },
    [theme.breakpoints.down(479)]: {
      height: '210px',
      marginTop: 0,
      paddingTop: 0,
    },
  },
  subtitle: {
    'text-align': 'center',
    width: '35%',
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 300,
    lineHeight: '26px',
    [theme.breakpoints.down(991)]: {
      width: '40%',
    },
    [theme.breakpoints.down(767)]: {
      fontSize: '15px',
      lineHeight: '19px',
    },
    [theme.breakpoints.down(479)]: {
      fontSize: '10px',
      lineHeight: '14px',
    },
  },
};
