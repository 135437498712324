import React, { FC } from 'react';
import { Box } from '@mui/material';
import { nployPartners } from 'constants/home-categories';
import useTranslate from 'utils/i18n/useTranslate';
import { HomePartner } from 'components/home/home-partner';
import { homePartnersStyles } from 'components/home/home-partners/home-partners.styles';

export const HomePartners: FC = () => {
  const { t } = useTranslate('home');
  return (
    <Box component="section" sx={homePartnersStyles.wrap}>
      <h4 className="tp---heading">
        {t('marketing.employersList')}{' '}
        <strong>{t('marketing.employers')}</strong>
      </h4>
      <Box sx={homePartnersStyles.logos}>
        <Box sx={homePartnersStyles.logos}>
          {nployPartners.map(({ id, logo }) => (
            <HomePartner iconClass={logo} key={id} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
