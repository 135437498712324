export const nployFeedbacks = [
  {
    id: 1,
    authorName: 'Ruska G.',
    authorPosition: 'CMS Specialist',
    comment: '"If the job ad is attractive, swipe right."',
  },
  {
    id: 2,
    authorName: 'Desislava G.',
    authorPosition: 'Account Executive',
    comment:
      '"The fact that employers make the first step towards the candidate takes away from the tension of the "search".',
  },
  {
    id: 3,
    authorName: 'Anna K.',
    authorPosition: 'Incident Manager',
    comment: '"<strong>nPloy</strong> saved me from my old job."',
  },
  {
    id: 4,
    authorName: 'Eleonora I.',
    authorPosition: 'Incident Manager',
    comment: `"At <strong>nPloy</strong>, I don't receive annoying job offers that don't match my search."`,
  },
  {
    id: 5,
    authorName: 'Prodan T.',
    authorPosition: 'Azure Compliance Expert',
    comment:
      '"We and the employer met halfway based on both of our requirements."',
  },
  {
    id: 6,
    authorName: 'Damian D.',
    authorPosition: 'Partner Operations',
    comment: '"I only see filtered job ads in which I have a real interest."',
  },
  {
    id: 7,
    authorName: 'Ruska G.',
    authorPosition: 'CMS Specialist',
    comment: '"If the job ad is attractive, swipe right."',
  },
  {
    id: 8,
    authorName: 'Desislava G.',
    authorPosition: 'Account Executive',
    comment:
      '"The fact that employers make the first step towards the candidate takes away from the tension of the "search".',
  },
  {
    id: 9,
    authorName: 'Anna K.',
    authorPosition: 'Incident Manager',
    comment: '"<strong>nPloy</strong> saved me from my old job."',
  },
  {
    id: 10,
    authorName: 'Eleonora I.',
    authorPosition: 'Incident Manager',
    comment: `"At <strong>nPloy</strong>, I don't receive annoying job offers that don't match my search."`,
  },
];
