import React, { FC } from 'react';
import Link from 'next/link';
import { Box } from '@mui/material';
import { homeCategories } from 'constants/home-categories';
import { CandidatesRoutes } from 'constants/routes';
import useTranslate from 'utils/i18n/useTranslate';
import { useAppDispatch } from 'hooks/*';
import { openCreateAccountModal } from 'store/reducers/uiReducer';
import { HomeCategoryItem } from 'components/home/home-category-item';

export const HomeCategories: FC = () => {
  const { t } = useTranslate('home');
  const dispatch = useAppDispatch();

  const handleOpenCreateAccountModal = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault();
    dispatch(openCreateAccountModal());
  };
  return (
    <Box component="section" className="homepage-jobs">
      <Box
        data-current="Tab 1"
        data-easing="ease"
        data-duration-in="300"
        data-duration-out="100"
        className="w-tabs"
      >
        <Box className="tabs-menu-2 w-tab-menu" role="tablist">
          <Link
            data-w-tab="Tab 1"
            className="inactive-tab w-inline-block w-tab-link w--current"
            id="w-tabs-0-data-w-tab-0"
            href="#w-tabs-0-data-w-pane-0"
            role="tab"
            aria-controls="w-tabs-0-data-w-pane-0"
            aria-selected="true"
          >
            <div className="text-block-43">
              {t('marketing.popularCategories')}
            </div>
          </Link>
        </Box>
        <Box className="tabs-content-2 w-tab-content">
          <Box
            data-w-tab="Tab 1"
            className="w-tab-pane w--tab-active"
            id="w-tabs-0-data-w-pane-0"
            role="tabpanel"
            aria-labelledby="w-tabs-0-data-w-tab-0"
          >
            <Box className="hj-jobs-cont">
              {homeCategories.map((item) => (
                <HomeCategoryItem
                  count={item.jobsCount}
                  key={item.id}
                  title={item.title}
                  iconClass={item.icon}
                  link={item.link}
                />
              ))}
            </Box>
          </Box>
          <Box data-w-tab="Tab 2" className="w-tab-pane" />
          <Box data-w-tab="Tab 3" className="w-tab-pane" />
        </Box>
      </Box>
      <Box className="w-layout-hflex hj-buttons">
        <Link
          href={{
            pathname: '/',
            query: { 'create-account': true },
          }}
          className="button dark w-button"
          onClick={handleOpenCreateAccountModal}
        >
          {t('marketing.createAccount')}
        </Link>
        <Link href={CandidatesRoutes.jobs} className="button outline w-button">
          {t('marketing.viewAllJobs')}
        </Link>
      </Box>
    </Box>
  );
};
