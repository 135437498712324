export const homePartnersStyles = {
  wrap: {
    backgroundColor: '#fff',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '30px 5% 40px',
    display: 'flex',
    position: 'relative',
  },
  logosWrapper: {
    maxWidth: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  logos: {
    overflowX: 'hidden',
    'grid-column-gap': '20px',
    'grid-row-gap': '16px',
    'flex-flow': 'wrap',
    'grid-template-rows': 'auto auto',
    'grid-template-columns': '1fr 1fr',
    'grid-auto-columns': '1fr',
    'align-content': 'flex-start',
    'justify-content': 'center',
    'align-items': 'flex-start',
    display: 'flex',
  },
};
